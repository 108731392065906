import * as React from 'react';
import { ScreenSizeEnum } from '../../../models/screen-size-enum';
import { TitleSection } from './title-section';
import { ReviewScroller } from './review-scroller';
import {
  DESKTOP_TITLE_EMPHASIZE_FONT_SIZE,
  DESKTOP_TITLE_FONT_SIZE,
  DESKTOP_TITLE_LINE_HEIGHT,
  MOBILE_SIDE_PADDING,
  MOBILE_TITLE_EMPHASIZE_FONT_SIZE,
  MOBILE_TITLE_FONT_SIZE,
  MOBILE_TITLE_LINE_HEIGHT,
  SECTION_GAP,
  TABLET_SIDE_PADDING,
  TABLET_TITLE_EMPHASIZE_FONT_SIZE,
  TABLET_TITLE_FONT_SIZE,
  TABLET_TITLE_LINE_HEIGHT,
} from '../../../styles/styles';
import { useIntersectionObserver } from '../../../hooks/use-intersection-observer';

type Props = {
  screenSize: ScreenSizeEnum;
};

export const ReviewsSection: React.FC<Props> = ({ screenSize }) => {
  /**
   * We only want the animation to run when this seciton is visible
   */
  const { ref: wrapperRef, isIntersecting: isWrapperVisible } = useIntersectionObserver({ threshold: 0 });

  return (
    <div ref={wrapperRef}>
      <div className="desktop-only">
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            padding: `${SECTION_GAP / 2}px 0 ${SECTION_GAP}px`,
          }}
        >
          <TitleSection
            fontSize={`${DESKTOP_TITLE_FONT_SIZE}px`}
            lineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
            emphasisFontSize={`${DESKTOP_TITLE_EMPHASIZE_FONT_SIZE}px`}
            emphasisLineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
            breakLines
          />
          <div style={{ marginTop: '92px', width: '100vw' }}>
            <ReviewScroller cardWidth={390} visible={isWrapperVisible && screenSize === ScreenSizeEnum.Desktop} />
          </div>
        </div>
      </div>
      <div className="tablet-only">
        <div
          style={{
            padding: `${SECTION_GAP / 2}px ${TABLET_SIDE_PADDING}px ${SECTION_GAP}px`,
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          <TitleSection
            fontSize={`${TABLET_TITLE_FONT_SIZE}px`}
            lineHeight={`${TABLET_TITLE_LINE_HEIGHT}px`}
            emphasisFontSize={`${TABLET_TITLE_EMPHASIZE_FONT_SIZE}px`}
            emphasisLineHeight={`${TABLET_TITLE_LINE_HEIGHT}px`}
            breakLines
          />
          <div style={{ marginTop: '73px', width: '100vw' }}>
            <ReviewScroller cardWidth={390} visible={isWrapperVisible && screenSize === ScreenSizeEnum.Tablet} />
          </div>
        </div>
      </div>
      <div className="mobile-only">
        <div
          style={{
            padding: `${SECTION_GAP / 2}px ${MOBILE_SIDE_PADDING}px ${SECTION_GAP}px`,
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          <TitleSection
            fontSize={`${MOBILE_TITLE_FONT_SIZE}px`}
            lineHeight={`${MOBILE_TITLE_LINE_HEIGHT}px`}
            emphasisFontSize={`${MOBILE_TITLE_EMPHASIZE_FONT_SIZE}px`}
            emphasisLineHeight={`${MOBILE_TITLE_LINE_HEIGHT}px`}
            breakLines
          />
          <div style={{ marginTop: '68px', width: '100vw' }}>
            <ReviewScroller cardWidth={390} visible={isWrapperVisible && screenSize === ScreenSizeEnum.Mobile} />
          </div>
        </div>
      </div>
    </div>
  );
};
