import * as React from 'react';
import appStoreUrl from './app-store.webp';
import googlePlayUrl from './google-play.webp';

type Props = {
  buttonWidth: string;
  buttonHeight: string;
  gap: string;
};

export function DownloadAppButtons({ buttonWidth, buttonHeight, gap }: Props) {
  return (
    <section style={{ display: 'flex', flexFlow: 'row', gap }}>
      <a
        href="https://itunes.apple.com/app/id448639966"
        target="__blank"
        data-event-track-id="bottom-apple-store-button"
      >
        <img alt="piccollage app store" src={appStoreUrl} width={buttonWidth} height={buttonHeight} loading="lazy" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.cardinalblue.piccollage.google"
        target="__blank"
        data-event-track-id="bottom-google-play-button"
      >
        <img
          alt="piccollage google play"
          src={googlePlayUrl}
          width={buttonWidth}
          height={buttonHeight}
          loading="lazy"
        />
      </a>
    </section>
  );
}
