import * as React from 'react';
import { images } from './images';

type Props = {};

export const LogoButton: React.FC<Props> = ({}) => {
  const [hovered, setHovered] = React.useState<boolean>(false);

  return (
    <a
      style={{
        display: 'flex',
        flexBasis: '14.5%',
        flexFlow: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: '16px',
        cursor: 'pointer',
      }}
      href="/"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        src={images.piccollageIconWebp}
        alt="piccollage logo"
        style={{ width: '204px', height: '37px' }}
        width={204}
        height={37}
        loading="lazy"
      />
    </a>
  );
};
