import * as React from 'react';
import backgroundFrame from './background-frame.webp';
import backgroundVideo from './background-video.mp4';
import backgroundVideoPoster from './background-video-poster.webp';
import decoHero1 from './deco-hero-1.webp';
import decoHero2 from './deco-hero-2.webp';

type Props = {
  hasDecorations?: boolean;
};

export function BackgroundSection({ hasDecorations }: Props) {
  return (
    <section style={{ position: 'relative', width: '100%', height: '100%' }}>
      <img
        style={{
          position: 'relative',
          zIndex: 2,
          width: 'calc(100% + 2px)',
          height: 'calc(100% + 2px)',
          transform: 'translateX(-1px) translateY(-1px)',
        }}
        alt="hero background border"
        src={backgroundFrame}
        width={1171}
        height={671}
      />
      <video
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          pointerEvents: 'none',
          background: `transparent url('${backgroundVideoPoster}') 50% 50% / cover no-repeat`,
        }}
        width="100%"
        height="100%"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      {hasDecorations && (
        <img
          style={{ position: 'absolute', bottom: '-94px', left: '-36px', width: '248px', height: '248px', zIndex: 3 }}
          width="248px"
          height="248px"
          alt="hero decoration trees"
          src={decoHero1}
        />
      )}
      {hasDecorations && (
        <img
          style={{ position: 'absolute', bottom: '-60px', right: '-60px', width: '200px', height: '200px', zIndex: 3 }}
          width="200px"
          height="200px"
          alt="hero decoration gingerbread man"
          src={decoHero2}
        />
      )}
    </section>
  );
}
