import * as React from 'react';
import { images } from '../images';

interface CancelCookieButtonProps {
  onClick: () => void;
}

export const CancelCookieButton: React.FC<CancelCookieButtonProps> = ({ onClick }) => {
  return (
    <div
      style={{
        width: '32px',
        height: '32px',
        backgroundColor: '#ffffff',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <img src={images.CancelIconWebp} alt="reject cookies usage cross icon" width={32} height={32} loading="lazy" />
    </div>
  );
};
