import * as React from 'react';
import { images } from '../../images';

type Props = {
  direction: 'left' | 'right';
  size: string;
  touchSize: string;
  opacity: number;
  arrowSize: string;
  onClick: () => void;
};

export const UseCaseButton: React.FC<Props> = ({ direction, size, touchSize, opacity, arrowSize, onClick }) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <button
      aria-label={`use case switch label ${direction}`}
      style={{
        width: touchSize,
        height: touchSize,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'none',
        outline: 'none',
        border: 'none',
        padding: '0',
      }}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '100%',
          backgroundColor: `rgba(255, 255, 255, ${opacity})`,
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
          width: size,
          height: size,
          cursor: hovered ? 'pointer' : 'default',
        }}
      >
        <img
          src={direction === 'left' ? images.buttons[0] : images.buttons[1]}
          alt="Back Icon"
          style={{ width: arrowSize, height: arrowSize }}
          width={arrowSize}
          height={arrowSize}
          loading="lazy"
        />
      </div>
    </button>
  );
};
