import {
  templatesImage,
  backgroundImage,
  stickersImage,
  fontsImage,
  templatesIcon,
  fontsIcon,
  stickersIcon,
  backgroundIcon,
} from './images';

export enum KeyMatricesCardNameEnum {
  Templates = 'Templates',
  Backgrounds = 'Backgrounds',
  Fonts = 'Fonts',
  Stickers = 'Stickers',
}

export const cards = [
  {
    image: templatesImage,
    imageSize: {
      width: 1152,
      height: 486,
    },
    imageScaleOnMobile: 1,
    iconImage: templatesIcon,
    title: KeyMatricesCardNameEnum.Templates,
    color: '#FFFAE8',
    animationDelay: 200,
  },
  {
    image: backgroundImage,
    imageSize: {
      width: 744,
      height: 486,
    },
    imageScaleOnMobile: 1,
    iconImage: backgroundIcon,
    title: KeyMatricesCardNameEnum.Backgrounds,
    color: '#FFE1DD',
    animationDelay: 600,
  },
  {
    image: fontsImage,
    imageSize: {
      width: 744,
      height: 486,
    },
    imageScaleOnMobile: 1,
    iconImage: fontsIcon,
    title: KeyMatricesCardNameEnum.Fonts,
    color: '#EAF3D4',
    animationDelay: 400,
  },
  {
    image: stickersImage,
    imageSize: {
      width: 1152,
      height: 486,
    },
    imageScaleOnMobile: 0.8,
    iconImage: stickersIcon,
    title: KeyMatricesCardNameEnum.Stickers,
    color: '#E6EDFF',
    animationDelay: 800,
  },
];
