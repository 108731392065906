import * as React from 'react';
import { reviews } from '../reviews';
import { ReviewCard } from '../review-card';

const INITIAL_LEFT_FILLER_WIDTH = 100000;
const INITIAL_RIGHT_FILLER_WIDTH = 100000;

type Props = {
  cardWidth: number;
  visible: boolean; // If the scroller currently visible?
};

export function ReviewScroller({ cardWidth, visible }: Props) {
  const cardGap = 24;
  const cloneFactor = 4;
  const prolivedReviews = React.useMemo(() => {
    let res: {
      title: string;
      date: string;
      reviewer: string;
      color: string;
      content: string;
    }[] = [];
    for (let i = 0; i < cloneFactor; i += 1) {
      res = res.concat(reviews);
    }
    return res;
  }, [cloneFactor, reviews]);

  const signleReviewsSectionWidth = React.useMemo(
    () => reviews.length * cardWidth + cardGap * (reviews.length - 1),
    [cardWidth, cardGap],
  );

  const reviewsSectionWidth = React.useMemo(
    () => signleReviewsSectionWidth * cloneFactor,
    [signleReviewsSectionWidth, cloneFactor],
  );

  const [carousellDom, setCarousellDom] = React.useState<HTMLElement | null>(null);

  const [leftFillerWidth, setLeftFillerWidth] = React.useState(() => INITIAL_LEFT_FILLER_WIDTH);
  const initialCarousellScrollLeft = React.useMemo(() => {
    return INITIAL_LEFT_FILLER_WIDTH + signleReviewsSectionWidth;
  }, [signleReviewsSectionWidth]);
  const currentCarousellScrollLeft = React.useRef(initialCarousellScrollLeft);

  React.useEffect(() => {
    if (!carousellDom) return;

    currentCarousellScrollLeft.current = initialCarousellScrollLeft;
    setLeftFillerWidth(INITIAL_LEFT_FILLER_WIDTH);
    carousellDom.scrollTo({ left: initialCarousellScrollLeft, behavior: 'instant' });
  }, [carousellDom, initialCarousellScrollLeft, visible]);

  const [contentRelativeLeft, setContentRelativeLeft] = React.useState(0);

  const handleScrollerScroll = React.useCallback(() => {
    if (!carousellDom) return;

    const scrollLeft = carousellDom.scrollLeft - currentCarousellScrollLeft.current;
    if (scrollLeft < 0) {
      currentCarousellScrollLeft.current = currentCarousellScrollLeft.current - signleReviewsSectionWidth;
      setLeftFillerWidth((prev) => prev - signleReviewsSectionWidth);
    } else if (scrollLeft > signleReviewsSectionWidth) {
      currentCarousellScrollLeft.current = currentCarousellScrollLeft.current + signleReviewsSectionWidth;
      setLeftFillerWidth((prev) => prev + signleReviewsSectionWidth);
    }
  }, [carousellDom, signleReviewsSectionWidth]);

  React.useEffect(() => {
    if (!visible) return () => {};

    const interval = setInterval(() => {
      setContentRelativeLeft((prev) => {
        if (prev - 1 < -signleReviewsSectionWidth) {
          return 0;
        }
        return prev - 1;
      });
    }, 25);

    return () => {
      clearInterval(interval);
    };
  }, [signleReviewsSectionWidth, visible]);

  return (
    <div
      ref={setCarousellDom}
      style={{
        overflow: 'auto',
        padding: '10px 0',
        margin: '-10px 0',
        display: 'flex',
        flexFlow: 'row',
      }}
      onScroll={handleScrollerScroll}
    >
      <div style={{ flexShrink: 0, width: leftFillerWidth }} />
      <div
        style={{
          position: 'relative',
          left: `${contentRelativeLeft}px`,
          width: reviewsSectionWidth,
          display: 'flex',
          flexFlow: 'row',
          gap: cardGap,
        }}
      >
        {prolivedReviews.map((review, reviewIndex) => (
          <div key={`${review.title}-${reviewIndex}`} style={{ flexShrink: 0 }}>
            <ReviewCard
              width={cardWidth}
              title={review.title}
              date={review.date}
              color={review.color}
              reviewer={review.reviewer}
              content={review.content}
            />
          </div>
        ))}
      </div>
      <div style={{ flexShrink: 0, width: INITIAL_RIGHT_FILLER_WIDTH }} />
    </div>
  );
}
