import usecaseVideoScreenshot1 from './images/usecase-video-1-screenshot.webp';
import usecaseVideoScreenshot2 from './images/usecase-video-2-screenshot.webp';
import usecaseVideoScreenshot3 from './images/usecase-video-3-screenshot.webp';
import usecaseVideo1 from './images/usecase-video-1.mp4';
import usecaseVideo2 from './images/usecase-video-2.mp4';
import usecaseVideo3 from './images/usecase-video-3.mp4';

export const cardInfos = [
  {
    title: 'Intuitive Tools',
    description:
      "PicCollage's tools are built to be helpful, easy-to-learn, and so good that (after trying them once) you can't live without them!",
    videoSource: usecaseVideo1,
    videoScreenshotSource: usecaseVideoScreenshot1,
  },
  {
    title: 'Create With AI-Powered Magic',
    description:
      'Our promise to you - our AI tools will always act as an assistant to super-charge your creative ideas. They blend in seamlessly and never take the creative process out of your hands.',
    videoSource: usecaseVideo2,
    videoScreenshotSource: usecaseVideoScreenshot2,
  },
  {
    title: 'Endless Designs',
    description:
      'Our design library is updated weekly for all occasions big and small. Never run out of ideas with templates, stickers, backgrounds, and fonts for literally everything.',
    videoSource: usecaseVideo3,
    videoScreenshotSource: usecaseVideoScreenshot3,
  },
];
