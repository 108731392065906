import * as React from 'react';

type Props = {
  title: string;
  videoSrc: string;
  videoScreenshotSrc: string;
  width: string | number;
  maxWidth?: string | number;
  height?: string | number;
  maxHeight?: string | number;
  borderRadius: string;
};

export function CardImageSection({
  title,
  videoScreenshotSrc,
  videoSrc,
  width,
  maxWidth,
  height,
  maxHeight,
  borderRadius,
}: Props) {
  return (
    <div
      style={{
        position: 'relative',
        width,
        maxWidth,
        height,
        maxHeight,
        display: 'inline-flex',
        borderRadius,
        overflow: 'hidden',
      }}
    >
      <video
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          pointerEvents: 'none',
          background: `transparent url('${videoScreenshotSrc}') 50% 50% / cover no-repeat`,
        }}
        width="100%"
        height="100%"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  );
}
