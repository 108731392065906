import * as React from 'react';
import { TitleSection } from './title-section/index';
import { CardInfoSection } from './card-info-section';
import { cardInfos } from './card-infos';
import { CardImageSection } from './card-image-section';
import {
  DESKTOP_MAX_WIDTH,
  DESKTOP_SIDE_PADDING,
  DESKTOP_SUBTITLE_FONT_SIZE,
  DESKTOP_SUBTITLE_LINE_HEIGHT,
  DESKTOP_TITLE_EMPHASIZE_FONT_SIZE,
  DESKTOP_TITLE_FONT_SIZE,
  DESKTOP_TITLE_LINE_HEIGHT,
  MOBILE_SIDE_PADDING,
  MOBILE_SUBTITLE_FONT_SIZE,
  MOBILE_TITLE_EMPHASIZE_FONT_SIZE,
  MOBILE_TITLE_FONT_SIZE,
  MOBILE_TITLE_LINE_HEIGHT,
  SECTION_GAP,
  TABLET_SIDE_PADDING,
  TABLET_SUBTITLE_FONT_SIZE,
  TABLET_SUBTITLE_LINE_HEIGHT,
  TABLET_TITLE_EMPHASIZE_FONT_SIZE,
  TABLET_TITLE_FONT_SIZE,
  TABLET_TITLE_LINE_HEIGHT,
} from '../../../styles/styles';

type Props = {};

export const KeySellingPointsSection: React.FC<Props> = ({}) => {
  return (
    <div>
      <div className="desktop-only" style={{ padding: `0 ${DESKTOP_SIDE_PADDING}px` }}>
        <div
          style={{
            maxWidth: `${DESKTOP_MAX_WIDTH}px`,
            margin: '0 auto',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            padding: `${SECTION_GAP / 2}px 0 ${SECTION_GAP}px`,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TitleSection
              fontSize={`${DESKTOP_TITLE_FONT_SIZE}px`}
              lineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
              emphasisFontSize={`${DESKTOP_TITLE_EMPHASIZE_FONT_SIZE}px`}
              emphasisLineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
              decoImgTop="-32px"
              decoImgRight="-48px"
              breakLine={false}
            />
          </div>
          <div
            style={{
              marginTop: '56px',
              display: 'flex',
              flexFlow: 'column',
              gap: '64px',
            }}
          >
            {cardInfos.map((cardInfo, cardInfoIndex) => (
              <div
                key={cardInfo.title}
                style={{
                  display: 'flex',
                  flexFlow: cardInfoIndex % 2 === 0 ? 'row' : 'row-reverse',
                  alignItems: 'center',
                  gap: '64px',
                }}
              >
                <div style={{ flex: '512 0 0' }}>
                  <CardInfoSection
                    title={cardInfo.title}
                    titleFontSize={`${DESKTOP_SUBTITLE_FONT_SIZE}px`}
                    titleLineHeight={`${DESKTOP_SUBTITLE_LINE_HEIGHT}px`}
                    description={cardInfo.description}
                    descriptionFontSize="18px"
                    descriptionLineHeightInPixel={25}
                    hasDescription
                    titleDescriptionGap="16px"
                  />
                </div>
                <div style={{ flex: '720 0 0', aspectRatio: '720/410' }}>
                  <CardImageSection
                    title={cardInfo.title}
                    videoSrc={cardInfo.videoSource}
                    videoScreenshotSrc={cardInfo.videoScreenshotSource}
                    width="100%"
                    height="100%"
                    borderRadius="40px"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="tablet-only">
        <div
          style={{
            padding: `${SECTION_GAP / 2}px ${TABLET_SIDE_PADDING}px ${SECTION_GAP / 2}px`,
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'column',
          }}
        >
          <TitleSection
            fontSize={`${TABLET_TITLE_FONT_SIZE}px`}
            lineHeight={`${TABLET_TITLE_LINE_HEIGHT}px`}
            emphasisFontSize={`${TABLET_TITLE_EMPHASIZE_FONT_SIZE}px`}
            emphasisLineHeight={`${TABLET_TITLE_LINE_HEIGHT}px`}
            decoImgTop="-32px"
            decoImgRight="-48px"
            breakLine={false}
          />
          <div
            style={{
              marginTop: '74px',
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              gap: '68px',
            }}
          >
            {cardInfos.map((cardInfo) => (
              <div
                key={cardInfo.title}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center',
                  gap: '24px',
                }}
              >
                <CardImageSection
                  title={cardInfo.title}
                  videoSrc={cardInfo.videoSource}
                  videoScreenshotSrc={cardInfo.videoScreenshotSource}
                  width={`calc(100vw - ${TABLET_SIDE_PADDING * 2}px)`}
                  maxWidth="712px"
                  height={`calc((100vw - ${TABLET_SIDE_PADDING}px) * 405 / 712)`}
                  maxHeight="405px"
                  borderRadius="40px"
                />
                <div style={{ display: 'inline-flex', width: '100%', maxWidth: '712px' }}>
                  <CardInfoSection
                    title={cardInfo.title}
                    titleFontSize={`${TABLET_SUBTITLE_FONT_SIZE}px`}
                    titleLineHeight={`${TABLET_SUBTITLE_LINE_HEIGHT}px`}
                    titleTextAlign="center"
                    description={cardInfo.description}
                    descriptionFontSize="18px"
                    descriptionLineHeightInPixel={28}
                    descriptionTextAlign="center"
                    hasDescription
                    titleDescriptionGap="8px"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mobile-only">
        <div
          style={{
            padding: `${SECTION_GAP / 2}px ${MOBILE_SIDE_PADDING}px ${SECTION_GAP / 2}px`,
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'column',
          }}
        >
          <TitleSection
            fontSize={`${MOBILE_TITLE_FONT_SIZE}px`}
            lineHeight={`${MOBILE_TITLE_LINE_HEIGHT}px`}
            emphasisFontSize={`${MOBILE_TITLE_EMPHASIZE_FONT_SIZE}px`}
            emphasisLineHeight={`${MOBILE_TITLE_LINE_HEIGHT}px`}
            decoImgTop="-46px"
            decoImgRight="-32px"
            breakLine={true}
          />
          <div
            style={{
              marginTop: '32px',
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              gap: '56px',
            }}
          >
            {cardInfos.map((cardInfo) => (
              <div
                key={cardInfo.title}
                style={{ width: '100%', display: 'flex', flexFlow: 'column', alignItems: 'center', gap: '28px' }}
              >
                <CardImageSection
                  title={cardInfo.title}
                  videoSrc={cardInfo.videoSource}
                  videoScreenshotSrc={cardInfo.videoScreenshotSource}
                  width={`calc(100vw - ${MOBILE_SIDE_PADDING * 2}px)`}
                  height={`calc((100vw - ${MOBILE_SIDE_PADDING * 2}px) * 405 / 712)`}
                  borderRadius="18px"
                />
                <CardInfoSection
                  title={cardInfo.title}
                  titleFontSize={`${MOBILE_SUBTITLE_FONT_SIZE}px`}
                  titleLineHeight={`${MOBILE_TITLE_LINE_HEIGHT}px`}
                  titleTextAlign="center"
                  description={cardInfo.description}
                  descriptionFontSize="16px"
                  descriptionLineHeightInPixel={20}
                  descriptionTextAlign="center"
                  hasDescription
                  titleDescriptionGap="12px"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
