import * as React from 'react';
import UseCaseScroller from './usecase-scroller';
import { TitleSection } from './title-section';
import { ScreenSizeEnum } from '../../../models/screen-size-enum';
import { getNextUsecase, getPrevUsecase, usecases } from './usecases';
import { UseCaseButton } from './buttons/usecase-button';
import { useWindowSize } from '../../../hooks/use-window-size';
import { CarousellTab } from '../../tabs/carousell-tab';
import { useDomRect } from '../../../hooks/use-dom-rect';
import {
  DESKTOP_DESCRIPTION_FONT_SIZE,
  DESKTOP_TITLE_EMPHASIZE_FONT_SIZE,
  DESKTOP_TITLE_LINE_HEIGHT,
  MOBILE_SIDE_PADDING,
  SECTION_GAP,
  TABLET_SIDE_PADDING,
} from '../../../styles/styles';
import { useIntersectionObserver } from '../../../hooks/use-intersection-observer';

type Props = {
  screenSize: ScreenSizeEnum;
};

export const UsecaseSection: React.FC<Props> = ({ screenSize }) => {
  const [activeUsecaseKey, setActiveUsecaseKey] = React.useState(() => usecases[0].key);

  const handleUsecaseTabClick = React.useCallback((usecaseKey: string) => {
    setActiveUsecaseKey(usecaseKey);
  }, []);

  const switchToPrevUsecase = React.useCallback(() => {
    setActiveUsecaseKey(getPrevUsecase(activeUsecaseKey).key);
  }, [activeUsecaseKey]);

  const switchToNextUsecase = React.useCallback(() => {
    setActiveUsecaseKey(getNextUsecase(activeUsecaseKey).key);
  }, [activeUsecaseKey]);

  const windowSize = useWindowSize();
  const windowWidth = React.useMemo(() => windowSize?.width ?? 0, [windowSize]);

  const desktopPhoneSize = React.useMemo(() => ({ width: 312, height: 636 }), []);

  const [titleSectionDom, setTitleSectionDom] = React.useState<HTMLElement | null>(null);
  const titleSectionDomRect = useDomRect(titleSectionDom);
  const desktopScrollerOffset = React.useMemo(
    () => titleSectionDomRect.left - desktopPhoneSize.width / 2 - 36,
    [windowWidth, desktopPhoneSize, titleSectionDomRect],
  );

  return (
    <div style={{ backgroundColor: '#FBF2EB' }}>
      <div className="desktop-only">
        <div
          style={{
            position: 'relative',
            width: '100vw',
            maxWidth: '1440px',
            margin: '0 auto',
            padding: `${SECTION_GAP / 2}px 0 ${SECTION_GAP}px`,
          }}
        >
          {usecases.map(({ key, deco1, deco2 }) => (
            <div
              key={key}
              style={{ opacity: key === activeUsecaseKey ? 1 : 0, WebkitTransition: 'opacity 0.3s ease-in-out' }}
            >
              <div style={{ position: 'absolute', top: '40px', left: '119px' }}>
                <img
                  alt="user scenario decoration"
                  src={deco1}
                  width={121}
                  height={121}
                  style={{ width: '121px' }}
                  loading="lazy"
                />
              </div>
              <div style={{ position: 'absolute', bottom: '35px', right: '181px' }}>
                <img
                  alt="user scenario decoration"
                  src={deco2}
                  width={121}
                  height={121}
                  style={{ width: '121px' }}
                  loading="lazy"
                />
              </div>
            </div>
          ))}
          <div
            style={{
              position: 'relative',
              padding: '0 40px',
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '48px',
              zIndex: 2,
            }}
          >
            <div style={{ display: 'flex', flexFlow: 'column', gap: '16px' }}>
              <div
                ref={setTitleSectionDom}
                style={{
                  position: 'relative',
                  width: '604px',
                  height: '120px',
                }}
              >
                {usecases.map(({ key, title, description }) => {
                  return (
                    <div
                      key={key}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        opacity: key === activeUsecaseKey ? 1 : 0,
                        WebkitTransition: 'opacity 0.3s ease-in-out',
                      }}
                    >
                      <TitleSection
                        title={title}
                        titleFontSize={`${DESKTOP_TITLE_EMPHASIZE_FONT_SIZE}px`}
                        titleLineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
                        description={description}
                        descriptionFontSize={`${DESKTOP_DESCRIPTION_FONT_SIZE}px`}
                        descriptionLineHeight="24px"
                        gap="8px"
                      />
                    </div>
                  );
                })}
              </div>
              <div>
                <CarousellTab
                  value={activeUsecaseKey}
                  options={usecases.map((usecase) => usecase.key)}
                  onClick={handleUsecaseTabClick}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <UseCaseButton
                size="56px"
                arrowSize="24px"
                opacity={1}
                touchSize="56px"
                direction="left"
                onClick={switchToPrevUsecase}
              />
              <UseCaseButton
                size="56px"
                arrowSize="24px"
                opacity={1}
                touchSize="56px"
                direction="right"
                onClick={switchToNextUsecase}
              />
            </div>
          </div>
          <div style={{ marginTop: '-80px', display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                width: '100vw',
              }}
            >
              <UseCaseScroller
                imageSize={{ width: 228, height: 383 }}
                imagePadding={20}
                phoneSize={desktopPhoneSize}
                scrollOffset={desktopScrollerOffset}
                activeUsecaseKey={activeUsecaseKey}
                onScrollToNextUsecase={switchToNextUsecase}
                onScrollToPrevUsecase={switchToPrevUsecase}
                visible={screenSize === ScreenSizeEnum.Desktop}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tablet-only">
        <div
          style={{
            padding: `${SECTION_GAP / 2}px ${TABLET_SIDE_PADDING}px ${SECTION_GAP}px`,
            display: 'flex',
            flexFlow: 'column',
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                position: 'relative',
                width: '623px',
                height: '128px',
              }}
            >
              {usecases.map(({ key, title, description }) => {
                return (
                  <div
                    key={key}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      opacity: key === activeUsecaseKey ? 1 : 0,
                      WebkitTransition: 'opacity 0.3s ease-in-out',
                    }}
                  >
                    <TitleSection
                      title={title}
                      titleFontSize="28px"
                      titleLineHeight="48px"
                      description={description}
                      descriptionFontSize="18px"
                      descriptionLineHeight="24px"
                      gap="8px"
                      textAlign="center"
                    />
                  </div>
                );
              })}
            </div>
            <div>
              <CarousellTab
                value={activeUsecaseKey}
                options={usecases.map((usecase) => usecase.key)}
                onClick={handleUsecaseTabClick}
              />
            </div>
          </div>
          <div style={{ marginTop: '18px', display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                position: 'relative',
                width: '100vw',
              }}
            >
              <div style={{ position: 'absolute', top: '50%', left: TABLET_SIDE_PADDING, zIndex: 2 }}>
                <UseCaseButton
                  size="56px"
                  arrowSize="24px"
                  opacity={0.8}
                  touchSize="56px"
                  direction="left"
                  onClick={switchToPrevUsecase}
                />
              </div>
              <UseCaseScroller
                imageSize={{ width: 228, height: 383 }}
                imagePadding={20}
                phoneSize={{ width: 312, height: 636 }}
                scrollOffset={windowWidth / 2}
                activeUsecaseKey={activeUsecaseKey}
                onScrollToNextUsecase={switchToNextUsecase}
                onScrollToPrevUsecase={switchToPrevUsecase}
                visible={screenSize === ScreenSizeEnum.Tablet}
              />
              <div style={{ position: 'absolute', top: '50%', right: TABLET_SIDE_PADDING, zIndex: 2 }}>
                <UseCaseButton
                  size="56px"
                  arrowSize="24px"
                  opacity={0.8}
                  touchSize="56px"
                  direction="right"
                  onClick={switchToNextUsecase}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-only">
        <div
          style={{
            padding: `${SECTION_GAP / 2}px ${MOBILE_SIDE_PADDING}px ${SECTION_GAP}px`,
            display: 'flex',
            flexFlow: 'column',
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '136px',
              }}
            >
              {usecases.map(({ key, title, description }) => {
                return (
                  <div
                    key={key}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      opacity: key === activeUsecaseKey ? 1 : 0,
                      WebkitTransition: 'opacity 0.3s ease-in-out',
                    }}
                  >
                    <TitleSection
                      title={title}
                      titleFontSize="24px"
                      titleLineHeight="30px"
                      description={description}
                      descriptionFontSize="16px"
                      descriptionLineHeight="18px"
                      gap="16px"
                      textAlign="center"
                    />
                  </div>
                );
              })}
            </div>
            <div style={{ marginTop: '16px' }}>
              <CarousellTab
                value={activeUsecaseKey}
                options={usecases.map((usecase) => usecase.key)}
                onClick={handleUsecaseTabClick}
              />
            </div>
          </div>
          <div style={{ position: 'relative', marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                position: 'relative',
                width: '100vw',
              }}
            >
              <div style={{ position: 'absolute', top: '50%', left: MOBILE_SIDE_PADDING - 8, zIndex: 2 }}>
                <UseCaseButton
                  size="32px"
                  opacity={0.8}
                  touchSize="48px"
                  direction="left"
                  arrowSize="14px"
                  onClick={switchToPrevUsecase}
                />
              </div>
              <div style={{ position: 'relative', zIndex: 1 }}>
                <UseCaseScroller
                  imageSize={{ width: 183, height: 307 }}
                  imagePadding={20}
                  phoneSize={{ width: 250, height: 510 }}
                  scrollOffset={windowWidth / 2}
                  activeUsecaseKey={activeUsecaseKey}
                  onScrollToNextUsecase={switchToNextUsecase}
                  onScrollToPrevUsecase={switchToPrevUsecase}
                  visible={screenSize === ScreenSizeEnum.Mobile}
                />
              </div>
              <div style={{ position: 'absolute', top: '50%', right: MOBILE_SIDE_PADDING - 8, zIndex: 2 }}>
                <UseCaseButton
                  size="32px"
                  opacity={0.8}
                  touchSize="48px"
                  direction="right"
                  arrowSize="14px"
                  onClick={switchToNextUsecase}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
