import * as React from 'react';
import { KeyMatricesCardNameEnum, cards } from '../cards';
import { KeyMatrixCard } from '../key-matrix-card';
import { useWindowSize } from '../../../../hooks/use-window-size';
import { CarousellTab } from '../../../tabs/carousell-tab';

interface Props {
  titleFontSize: string;
  titleLineHeight: string;
  visible: boolean; // Is the carousell visible?
}

const INITIAL_LEFT_FILLER_WIDTH = 100000;
const INITIAL_RIGHT_FILLER_WIDTH = 100000;

const KeyMatricesCarousell: React.FC<Props> = ({ titleFontSize, titleLineHeight, visible }: Props) => {
  const windowSize = useWindowSize();

  const cardWidth = React.useMemo(() => 680, []);
  const cardHeight = React.useMemo(() => 520, []);
  const cardContainerPadding = React.useMemo(() => 12, []);
  const cardContainerWidth = React.useMemo(
    () => cardWidth + cardContainerPadding * 2,
    [cardWidth, cardContainerPadding],
  );
  const cardsCount = React.useMemo(() => cards.length, []);

  const cloneFactor = 4;

  const cardsSectionWidth = React.useMemo(
    () => cards.length * cloneFactor * cardContainerWidth,
    [cloneFactor, cardContainerWidth],
  );

  const [carousellDom, setCarousellDom] = React.useState<HTMLElement | null>(null);

  const [leftFillerWidth, setLeftFillerWidth] = React.useState(() => INITIAL_LEFT_FILLER_WIDTH);

  const carousellScrollOffset = React.useMemo(() => {
    if (!windowSize) return;

    return (windowSize.width % cardContainerWidth) / 2;
  }, [windowSize, cardContainerWidth]);

  const initialCarousellScrollLeft = React.useMemo(() => {
    if (!carousellScrollOffset) return;

    return INITIAL_LEFT_FILLER_WIDTH + cardsCount * cardContainerWidth - carousellScrollOffset;
  }, [carousellScrollOffset, cardContainerWidth, cardsCount]);

  const currentCarousellScrollLeft = React.useRef(initialCarousellScrollLeft);

  const [activeCardTitle, setActiveCardTitle] = React.useState(KeyMatricesCardNameEnum.Templates);

  const scrollCarousellByCardIndexOffset = React.useCallback(
    (cardIndexOffset: number, behavior: 'instant' | 'smooth') => {
      if (!carousellDom) return;
      if (!currentCarousellScrollLeft.current) return;

      const cardIndex = cards.findIndex((card) => card.title === activeCardTitle);

      carousellDom.scrollTo({
        left: Math.round(currentCarousellScrollLeft.current + (cardIndex + cardIndexOffset) * cardContainerWidth),
        behavior,
      });
    },
    [carousellDom, cardContainerWidth, activeCardTitle],
  );

  const prolivedCards = React.useMemo(() => [...cards, ...cards, ...cards], []);

  /**
   * Remember to reset the scroll left when the carousell becomes visible
   */
  React.useEffect(() => {
    if (!carousellDom) return;
    if (!visible) return;

    currentCarousellScrollLeft.current = initialCarousellScrollLeft;
    setLeftFillerWidth(INITIAL_LEFT_FILLER_WIDTH);
    scrollCarousellByCardIndexOffset(0, 'instant');
    setActiveCardTitle(cards[0].title);
  }, [carousellDom, initialCarousellScrollLeft, visible]);

  const scrollToNextCard = React.useCallback(() => {
    scrollCarousellByCardIndexOffset(1, 'smooth');
  }, [scrollCarousellByCardIndexOffset, activeCardTitle]);

  const carousellScrollToNextTimeout = React.useRef<NodeJS.Timer>();
  const carousellScrollStopTimeout = React.useRef<NodeJS.Timer>();
  const prevCarousellScrollLeft = React.useRef<number | null>(null);
  const handleCarousellScroll = React.useCallback(() => {
    if (!carousellDom) return;
    if (!currentCarousellScrollLeft.current) return;
    if (!visible) return;

    // Check when scroll stops for a while
    clearTimeout(carousellScrollToNextTimeout.current);
    carousellScrollToNextTimeout.current = setTimeout(() => {
      scrollToNextCard();
    }, 1500);

    const carousellDomScrollLeft = carousellDom.scrollLeft;
    if (!prevCarousellScrollLeft.current) {
      prevCarousellScrollLeft.current = carousellDomScrollLeft;
    }

    // Check when carousell stops scrolling
    const scrollDir = carousellDomScrollLeft > prevCarousellScrollLeft.current ? 'right' : 'left';
    clearTimeout(carousellScrollStopTimeout.current);
    carousellScrollStopTimeout.current = setTimeout(() => {
      if (!currentCarousellScrollLeft.current) return;

      let scrollLeftMargin = (carousellDom.scrollLeft - currentCarousellScrollLeft.current) % cardContainerWidth;
      scrollLeftMargin = Math.round(scrollLeftMargin + cardContainerWidth) % cardContainerWidth;

      if (scrollLeftMargin > 2) {
        carousellDom.scrollBy({
          left: scrollDir === 'right' ? cardContainerWidth - scrollLeftMargin : -scrollLeftMargin,
          behavior: 'smooth',
        });
      }
    }, 200);

    prevCarousellScrollLeft.current = carousellDom.scrollLeft;

    const cardIndex = Math.round((carousellDom.scrollLeft - currentCarousellScrollLeft.current) / cardContainerWidth);

    if (cardIndex >= cardsCount) {
      currentCarousellScrollLeft.current = currentCarousellScrollLeft.current + cardContainerWidth * cardsCount;
      setLeftFillerWidth((prev) => prev + cardContainerWidth * cardsCount);
    } else if (cardIndex < 0) {
      currentCarousellScrollLeft.current = currentCarousellScrollLeft.current - cardContainerWidth * cardsCount;
      setLeftFillerWidth((prev) => prev - cardContainerWidth * cardsCount);
    }

    const activeCardIndex = (cardIndex + cardsCount) % cardsCount;
    setActiveCardTitle(cards[activeCardIndex].title);
  }, [carousellDom, cardContainerWidth, scrollToNextCard, visible]);

  const scrollToCard = React.useCallback(
    (cardTitle: string) => {
      if (!carousellDom) return;
      if (!carousellScrollOffset) return;

      const targetCardIndex = cards.findIndex((card) => card.title === cardTitle);
      const activeCardIndex = cards.findIndex((card) => card.title === activeCardTitle);
      const cardIndexOffset = targetCardIndex - activeCardIndex;

      if (Math.abs(cardIndexOffset) <= cards.length / 2) {
        const targetLeft =
          leftFillerWidth +
          cardsSectionWidth / cloneFactor +
          targetCardIndex * cardContainerWidth -
          carousellScrollOffset;

        carousellDom.scrollTo({
          left: targetLeft,
          behavior: 'smooth',
        });
      } else {
        if (targetCardIndex < activeCardIndex) {
          const targetLeft =
            leftFillerWidth +
            cardsSectionWidth / cloneFactor +
            (targetCardIndex + cards.length) * cardContainerWidth -
            carousellScrollOffset;

          carousellDom.scrollTo({
            left: targetLeft,
            behavior: 'smooth',
          });
        } else {
          const targetLeft =
            leftFillerWidth +
            cardsSectionWidth / cloneFactor +
            (targetCardIndex - cards.length) * cardContainerWidth -
            carousellScrollOffset;

          carousellDom.scrollTo({
            left: targetLeft,
            behavior: 'smooth',
          });
        }
      }
    },
    [
      carousellDom,
      cards,
      activeCardTitle,
      carousellScrollOffset,
      cardContainerWidth,
      cardsSectionWidth,
      cloneFactor,
      leftFillerWidth,
    ],
  );

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div
        ref={setCarousellDom}
        style={{
          position: 'relative',
          left: 0,
          padding: '0 16px',
          display: 'flex',
          overflowX: 'auto',
          paddingBottom: '16px',
        }}
        onScroll={handleCarousellScroll}
      >
        <div style={{ width: leftFillerWidth, flexShrink: 0 }} />
        <div style={{ flexShrink: 0, display: 'flex', position: 'relative' }}>
          {prolivedCards.map((card, cardIndex) => (
            <div
              key={`${card.title}-${cardIndex}`}
              style={{
                flexShrink: '0',
                width: cardContainerWidth,
                display: 'flex',
                justifyContent: 'center',
                padding: `0 ${cardContainerPadding}px`,
                cursor: 'pointer',
              }}
              onClick={() => {
                scrollToCard(card.title);
              }}
            >
              <KeyMatrixCard
                image={card.image}
                iconImage={card.iconImage}
                imageWidth={card.imageSize.width}
                imageHeight={card.imageSize.height}
                iconSize="40px"
                title={card.title}
                titleFontSize={titleFontSize}
                titleLineHeight={titleLineHeight}
                titleTop="62px"
                titleLeft="62px"
                width={`${cardWidth}px`}
                height={`${cardHeight}px`}
                borderRadius="40px"
                backgroundColor={card.color}
                animationDelay={card.animationDelay}
              />
            </div>
          ))}
        </div>
        <div style={{ width: INITIAL_RIGHT_FILLER_WIDTH, flexShrink: 0 }} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '8px',
          marginTop: '5px',
        }}
      >
        <CarousellTab value={activeCardTitle} options={cards.map((card) => card.title)} onClick={scrollToCard} />
      </div>
    </div>
  );
};

export default KeyMatricesCarousell;
