import * as React from 'react';
import { useScreenSize } from '../features/landing-page/hooks/use-screen-size';
import { FixedBottomSection } from '../features/landing-page/components/sections/fixed-bottom-section';
import { NavbarSection } from '../features/landing-page/components/sections/navbar-section';
import { HeroSection } from '../features/landing-page/components/sections/hero-section';
import { UsecaseSection } from '../features/landing-page/components/sections/usecase-section';
import { KeySellingPointsSection } from '../features/landing-page/components/sections/key-selling-points-section';
import { KeyCreativeToolsSection } from '../features/landing-page/components/sections/key-creative-tools-section';
import { ReviewsSection } from '../features/landing-page/components/sections/reviews-section';
import { FooterSection } from '../features/landing-page/components/sections/footer-section';

type Props = {};

const LandingPageContent: React.FC<Props> = ({}) => {
  const screenSize = useScreenSize();

  return (
    <>
      <div>
        <NavbarSection />
        <div style={{ overflow: 'hidden' }}>
          <HeroSection />
          <UsecaseSection screenSize={screenSize} />
          <KeySellingPointsSection />
          <KeyCreativeToolsSection screenSize={screenSize} />
          <ReviewsSection screenSize={screenSize} />
          <FooterSection />
        </div>
        <FixedBottomSection />
      </div>
    </>
  );
};

const LandingPage: React.FC<Props> = (props) => {
  return (
    <>
      <LandingPageContent {...props} />
    </>
  );
};

export default LandingPage;
