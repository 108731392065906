import * as React from 'react';
import appStoreUrl from './app-store.webp';
import googlePlayUrl from './google-play.webp';

type Props = {
  buttonWidth: number;
};

export function DownloadAppButtons({ buttonWidth }: Props) {
  return (
    <section style={{ display: 'flex', flexFlow: 'row', gap: '18px' }}>
      <a href="https://itunes.apple.com/app/id448639966" target="__blank" data-event-track-id="top-apple-store-button">
        <img alt="piccollage app store" src={appStoreUrl} width={buttonWidth} height={(buttonWidth * 80) / 245} />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.cardinalblue.piccollage.google"
        target="__blank"
        data-event-track-id="top-google-play-button"
      >
        <img alt="piccollage google play" src={googlePlayUrl} width={buttonWidth} height={(buttonWidth * 80) / 245} />
      </a>
    </section>
  );
}
