import * as React from 'react';
import { Icon } from '@iconify/react';
import { Text } from '../../text';
import { NavItem } from './nav-item-groups';

type Props = {
  isOpen: boolean;
  linkSize: string;
  navItems: NavItem[];
  closeMenu: () => void;
};

const HamburgerMenu: React.FC<Props> = ({ isOpen, linkSize, navItems, closeMenu }) => {
  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          height: '100%',
          width: '256px',
          backgroundColor: '#353535',
          transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
          transition: 'transform 300ms ease-in-out',
          zIndex: 50,
        }}
      >
        <div
          onClick={closeMenu}
          style={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            background: 'none',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            padding: '4px',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = '#999')}
          onMouseLeave={(e) => (e.currentTarget.style.color = 'white')}
        >
          <Icon icon="majesticons:close-line" width="24px" height="24px" />
        </div>

        <nav style={{ paddingTop: '64px', paddingLeft: '24px', paddingRight: '24px' }}>
          {navItems.map((item, index) => (
            <a
              key={index}
              style={{
                display: 'flex',
                padding: '12px 0',
                color: 'white',
                fontSize: '18px',
                textDecoration: 'none',
                transition: 'color 200ms ease',
                cursor: 'pointer',
              }}
              href={item.path}
              target={item.newTab ? '_blank' : undefined}
              onMouseOver={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <Text
                tag="span"
                fontFamily="NewAvertaPE"
                fontWeight="400"
                fontSize={linkSize}
                color={hoveredIndex === index ? '#999' : 'white'}
                lineHeight="1"
              >
                {item.title}
              </Text>
            </a>
          ))}
        </nav>
      </div>
    </>
  );
};

export default HamburgerMenu;
