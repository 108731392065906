import * as React from 'react';
import { Text } from '../../../text';

interface Props {
  copy: string;
  onClick?: () => void;
}

export function AcceptCookieButton({ copy, onClick }: React.PropsWithChildren<Props>) {
  return (
    <button
      style={{
        borderRadius: '10px',
        outline: 'none',
        background: '#4FC3C4',
        width: '70px',
        height: '33px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Text tag="span" fontSize="14px" fontFamily="NewAvertaPE" color="white" fontWeight="700">
        {copy}
      </Text>
    </button>
  );
}
