import * as React from 'react';
import { images } from '../images';
import { getUsecaseImages, usecases } from '../usecases';
import { useWindowSize } from '../../../../hooks/use-window-size';
import { UsecaseImage } from './usecase-image';

type UsecaseImagesMap = Record<string, { key: string; src: string }[]>;

type Props = {
  imageSize: {
    width: number;
    height: number;
  };
  imagePadding: number;
  phoneSize: {
    width: number;
    height: number;
  };
  scrollOffset: number;
  activeUsecaseKey: string;
  onScrollToNextUsecase: () => void;
  onScrollToPrevUsecase: () => void;
  visible: boolean;
};

const INITIAL_LEFT_FILLER_WIDTH = 100000;
const INITIAL_RIGHT_FILLER_WIDTH = 100000;

const UseCaseScroller: React.FC<Props> = ({
  imageSize,
  imagePadding,
  phoneSize,
  scrollOffset,
  activeUsecaseKey,
  onScrollToNextUsecase,
  onScrollToPrevUsecase,
  visible,
}) => {
  const windowSize = useWindowSize();

  const activeUsecaseImages = React.useMemo(() => getUsecaseImages(activeUsecaseKey), [activeUsecaseKey]);
  const activeUsecaseImagesCount = React.useMemo(() => activeUsecaseImages.length, [activeUsecaseImages]);
  const getActiveUsecaseImageIndex = React.useCallback(
    (key: string) => activeUsecaseImages.findIndex((image) => image.key === key),
    [activeUsecaseImages],
  );

  const cloneFactor = 3;
  const prolivedUsecaseImagesMap = React.useMemo<{ [usecaseKey: string]: { key: string; src: string }[] }>(() => {
    return usecases.reduce<UsecaseImagesMap>((sum, curr) => {
      sum[curr.key] = [...curr.images, ...curr.images, ...curr.images];
      return sum;
    }, {});
  }, []);

  const cardScale = 1.24;
  const cardWidth = React.useMemo(() => imageSize.width, []);
  const cardHeight = React.useMemo(() => imageSize.height, []);
  const cardContainerPadding = React.useMemo(() => imagePadding, []);
  const cardContainerWidth = React.useMemo(
    () => cardWidth + cardContainerPadding * 2,
    [cardWidth, cardContainerPadding],
  );

  const singleCardsSectionWidth = React.useMemo(() => {
    return activeUsecaseImagesCount * cardContainerWidth;
  }, [activeUsecaseImagesCount, cardContainerWidth]);

  const [carousellDom, setCarousellDom] = React.useState<HTMLElement | null>(null);
  const [leftFillerWidth, setLeftFillerWidth] = React.useState(() => INITIAL_LEFT_FILLER_WIDTH);

  const initialCarousellScrollLeft = React.useMemo(() => {
    return INITIAL_LEFT_FILLER_WIDTH + singleCardsSectionWidth - (scrollOffset - cardContainerWidth / 2);
  }, [scrollOffset, singleCardsSectionWidth, cardContainerWidth]);

  const currentCarousellScrollLeft = React.useRef(initialCarousellScrollLeft);

  React.useEffect(() => {
    if (!windowSize) return;
    if (!visible) return;

    currentCarousellScrollLeft.current = initialCarousellScrollLeft;
    setLeftFillerWidth(INITIAL_LEFT_FILLER_WIDTH);
    scrollContainerByCardIndexOffset(0, 'instant');
  }, [windowSize, initialCarousellScrollLeft, visible]);

  const scrollContainerByCardIndexOffset = React.useCallback(
    (cardIndexOffset: number, behavior: 'instant' | 'smooth') => {
      if (!carousellDom) return;

      carousellDom.scrollTo({
        left: currentCarousellScrollLeft.current + cardIndexOffset * cardContainerWidth,
        behavior,
      });
    },
    [carousellDom, cardContainerWidth],
  );

  const useCaseImageToken = React.useId();
  const generateUsecaseImageDataId = React.useCallback(
    (usecaseImageKey: string) => {
      return `usecase-image-${usecaseImageKey}-${useCaseImageToken}`;
    },
    [useCaseImageToken],
  );
  const generateUsecaseImageDataIndex = React.useCallback(
    (usecaseKey: string, index: number) => {
      return `usecase-image-${usecaseKey}-${index}-${useCaseImageToken}`;
    },
    [useCaseImageToken],
  );

  const scrollToNextUsecaseImage = React.useCallback(() => {
    const nextActiveUsecaseImageIndex = getActiveUsecaseImageIndex(activeUsecaseImageKey.current) + 1;
    scrollContainerByCardIndexOffset(nextActiveUsecaseImageIndex, 'smooth');
  }, [scrollContainerByCardIndexOffset, getActiveUsecaseImageIndex]);

  const activeUsecaseImageKey = React.useRef('');
  const activateUsecaseImage = React.useCallback(
    (activeImageKey: string) => {
      const dataId = generateUsecaseImageDataId(activeImageKey);
      // @ts-expect-error
      const activeUsecaseImagesElems = document.querySelectorAll(`[data-id="${dataId}"]`) as HTMLElement[];
      activeUsecaseImagesElems.forEach((activeUsecaseImagesElem) => {
        activeUsecaseImagesElem.style.transform = `scale(${cardScale})`;
        activeUsecaseImagesElem.style.borderRadius = '0';
        activeUsecaseImagesElem.style.left = '0';
        activeUsecaseImageKey.current = activeImageKey;
      });

      const activeUsecaseImageKeys = activeUsecaseImages.map((activeUsecaseImage) => activeUsecaseImage.key);

      // Non-active usecase images
      activeUsecaseImageKeys.forEach((imageKey) => {
        if (activeImageKey === imageKey) return;

        const prevDataId = generateUsecaseImageDataId(imageKey);
        // @ts-expect-error
        const prevActiveUsecaseImagesElems = document.querySelectorAll(`[data-id="${prevDataId}"]`) as HTMLElement[];
        prevActiveUsecaseImagesElems.forEach((prevActiveUsecaseImagesElem) => {
          prevActiveUsecaseImagesElem.style.transform = `scale(1)`;
          prevActiveUsecaseImagesElem.style.borderRadius = '20px';
        });
      });

      // Go through sibling elems and set their left to keep gaps consistent
      const activeUsecaseImageDomIndex =
        activeUsecaseImageKeys.findIndex((key) => key === activeImageKey) + activeUsecaseImagesCount;

      usecases.forEach((usecase) => {
        for (let i = -(activeUsecaseImagesCount - 1); i < activeUsecaseImagesCount; i += 1) {
          if (i !== 0) {
            const dataIndex = generateUsecaseImageDataIndex(usecase.key, activeUsecaseImageDomIndex + i);
            // @ts-expect-error
            const prevActiveUsecaseImagesElems = document.querySelectorAll(
              `[data-index="${dataIndex}"]`,
            ) as HTMLElement[];
            const sibliingUsecaseImageElem = prevActiveUsecaseImagesElems[0];
            sibliingUsecaseImageElem.style.left = i < 0 ? '-20px' : '20px';
          }
        }
      });
    },
    [
      cardScale,
      cardWidth,
      cardHeight,
      activeUsecaseImages,
      activeUsecaseImagesCount,
      useCaseImageToken,
      generateUsecaseImageDataIndex,
    ],
  );

  React.useEffect(() => {
    activateUsecaseImage(activeUsecaseImageKey.current);
  }, [activeUsecaseImages]);

  const carousellNextImageTimeout = React.useRef<NodeJS.Timer>();
  const carousellScrollStopTimeout = React.useRef<NodeJS.Timer>();
  const prevCarousellScrollLeft = React.useRef<number | null>(null);
  const handleCarouselScroll = React.useCallback(() => {
    if (!visible) return;
    if (!carousellDom) return;

    // Runs when the scrolling stops for a while
    clearTimeout(carousellNextImageTimeout.current);
    carousellNextImageTimeout.current = setTimeout(() => {
      scrollToNextUsecaseImage();
    }, 1800);

    const carousellDomScrollLeft = carousellDom.scrollLeft;
    if (!prevCarousellScrollLeft.current) {
      prevCarousellScrollLeft.current = carousellDomScrollLeft;
    }

    const scrollDir = carousellDomScrollLeft > prevCarousellScrollLeft.current ? 'right' : 'left';

    // Runs immediately when the scrolling stops
    clearTimeout(carousellScrollStopTimeout.current);
    carousellScrollStopTimeout.current = setTimeout(() => {
      let scrollLeftMargin = (carousellDom.scrollLeft - currentCarousellScrollLeft.current) % cardContainerWidth;
      scrollLeftMargin = Math.round(scrollLeftMargin + cardContainerWidth) % cardContainerWidth;

      if (scrollLeftMargin > 2) {
        carousellDom.scrollBy({
          left: scrollDir === 'right' ? cardContainerWidth - scrollLeftMargin : -scrollLeftMargin,
          behavior: 'smooth',
        });
      }
    }, 200);

    prevCarousellScrollLeft.current = carousellDom.scrollLeft;

    const carousellScrollLeftUsecaseImageIndexOffset = Math.round(
      (carousellDomScrollLeft - currentCarousellScrollLeft.current) / cardContainerWidth,
    );

    if (carousellScrollLeftUsecaseImageIndexOffset >= activeUsecaseImagesCount) {
      currentCarousellScrollLeft.current = currentCarousellScrollLeft.current + singleCardsSectionWidth;
      setLeftFillerWidth((prev) => prev + singleCardsSectionWidth);
      onScrollToNextUsecase();
    } else if (carousellScrollLeftUsecaseImageIndexOffset < 0) {
      currentCarousellScrollLeft.current = currentCarousellScrollLeft.current - singleCardsSectionWidth;
      setLeftFillerWidth((prev) => prev - singleCardsSectionWidth);
      onScrollToPrevUsecase();
    }

    const activeSourceUsecaseImageIndex =
      (carousellScrollLeftUsecaseImageIndexOffset + activeUsecaseImagesCount) % activeUsecaseImagesCount;
    activateUsecaseImage(activeUsecaseImages[activeSourceUsecaseImageIndex].key);
  }, [
    visible,
    activateUsecaseImage,
    activeUsecaseImages,
    activeUsecaseImagesCount,
    cardContainerWidth,
    singleCardsSectionWidth,
    carousellDom,
    scrollToNextUsecaseImage,
    onScrollToNextUsecase,
    onScrollToPrevUsecase,
  ]);

  // React.useEffect(() => {
  //   if (!carousellDom) return;
  //   if (!scrollOffset) return;

  //   scrollContainerByCardIndexOffset(0, 'instant');
  //   activateUsecaseImage(prolivedUsecaseImagesMap[activeUsecaseKey][0].key);
  // }, [carousellDom, scrollContainerByCardIndexOffset, scrollOffset]);

  return (
    <div style={{ position: 'relative', width: '100%', display: 'flex' }}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          transform: `translateX(calc(${scrollOffset}px - 50%)) translateY(-50%)`,
          zIndex: 2,
          pointerEvents: 'none',
          flexShrink: 0,
        }}
      >
        <img
          style={{
            position: 'relative',
            width: `${phoneSize.width}px`,
            height: `${phoneSize.height}px`,
            objectFit: 'cover',
            zIndex: 2,
          }}
          width={phoneSize.width}
          height={phoneSize.height}
          src={images.phoneMockup}
          alt="usecase phone mockup"
          loading="lazy"
        />
        <img
          style={{
            position: 'absolute',
            left: '50%',
            bottom: '-47px',
            transform: 'translateX(-50%)',
            width: '500px',
            height: '98px',
            zIndex: 1,
          }}
          alt="hero mobile shadow"
          src={images.phoneShadow}
          width={500}
          height={98}
          loading="lazy"
        />
      </div>
      <div
        ref={setCarousellDom}
        style={{
          height: phoneSize.height,
          display: 'flex',
          alignItems: 'center',
          overflowX: 'auto',
        }}
        onScroll={handleCarouselScroll}
      >
        <div style={{ flexShrink: 0, width: leftFillerWidth, height: imageSize.height }} />
        <div
          style={{
            position: 'relative',
            width: cloneFactor * singleCardsSectionWidth,
            height: phoneSize.height,
            flexShrink: 0,
            overflow: 'hidden',
          }}
        >
          {Object.entries(prolivedUsecaseImagesMap).map(([usecaseKey, prolivedUsecaseImages]) => {
            return (
              <div
                key={usecaseKey}
                style={{
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  opacity: usecaseKey === activeUsecaseKey ? 1 : 0,
                  WebkitTransition: 'opacity 0.3s ease',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                  }}
                >
                  <section
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                    }}
                  >
                    {prolivedUsecaseImages.map((usecaseImage, usecaseImageIndex) => (
                      <div
                        key={`usecase-template-${usecaseImage.key}-${usecaseImageIndex}`}
                        style={{
                          width: cardContainerWidth,
                          height: cardHeight,
                          flexShrink: '0',
                          padding: '0 20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          data-id={generateUsecaseImageDataId(usecaseImage.key)}
                          data-index={generateUsecaseImageDataIndex(usecaseKey, usecaseImageIndex)}
                          style={{
                            position: 'relative',
                            width: `${cardWidth}px`,
                            height: `${cardHeight}px`,
                            WebkitTransition: 'transform 0.3s ease, border-radius 0.3s ease',
                            borderRadius: '0',
                            overflow: 'hidden',
                          }}
                        >
                          <UsecaseImage
                            alt={`use case template ${usecaseImage.key.toLowerCase()}`}
                            src={usecaseImage.src}
                            width={cardWidth}
                            height={cardHeight}
                            loadImage={visible}
                          />
                        </div>
                      </div>
                    ))}
                  </section>
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ flexShrink: 0, width: INITIAL_RIGHT_FILLER_WIDTH }} />
      </div>
    </div>
  );
};

export default UseCaseScroller;
