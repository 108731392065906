import * as React from 'react';
import { Icon } from '@iconify/react';
import {
  desktopHambergurItemGroups,
  mobileHambergurItemGroups,
  navItemGroups,
  tabletHambergurItemGroups,
} from './nav-item-groups';
import { Text } from '../../../components/text';
import HamburgerMenu from './hamburger-menu';
import { DownloadAppButton } from '../../buttons/download-app-button';
import { MenuButton } from './menu-button';
import { LogoButton } from './logo-button';
import {
  DESKTOP_NAVBAR_LINK_FONT_SIZE,
  MOBILE_NAVBAR_LINK_FONT_SIZE,
  TABLET_NAVBAR_LINK_FONT_SIZE,
} from '../../../styles/styles';

type Props = {};

export const NavbarSection: React.FC<Props> = ({}) => {
  const [hoveredNavBarItemTitle, setHoveredNavBarItemTitle] = React.useState<string | null>(null);
  const [activeNavBarItemTitle, setActiveNavBarItemTitle] = React.useState<string | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const openHamburgerMenu = () => {
    setIsOpen(true);
  };

  const closeHamburgerMenu = () => {
    setIsOpen(false);
  };

  const handleNavItemMouseClick = React.useCallback((title: string) => {
    return () => {
      setActiveNavBarItemTitle(title);
    };
  }, []);

  const handleNavItemMouseEnter = React.useCallback((title: string) => {
    return () => {
      setHoveredNavBarItemTitle(title);
    };
  }, []);

  const handleNavItemMouseLeave = React.useCallback(() => {
    setHoveredNavBarItemTitle(null);
    setActiveNavBarItemTitle(null);
  }, []);

  return (
    <section style={{ position: 'sticky', top: 0, left: 0, zIndex: 9999 }}>
      <div className="mobile-only">
        <div
          style={{
            width: '100%',
            height: '65px',
            padding: '15px 20px',
            backgroundColor: 'white',
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <section
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 'inherit',
            }}
          >
            <LogoButton />
            <MenuButton onClick={openHamburgerMenu} />
            <HamburgerMenu
              isOpen={isOpen}
              linkSize={`${MOBILE_NAVBAR_LINK_FONT_SIZE}px`}
              navItems={mobileHambergurItemGroups}
              closeMenu={closeHamburgerMenu}
            />
          </section>
        </div>
      </div>
      <div className="tablet-only">
        <div
          style={{
            width: '100%',
            height: '65px',
            padding: '15px 20px',
            backgroundColor: 'white',
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <section
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 'inherit',
            }}
          >
            <LogoButton />
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                gap: '14px',
              }}
            >
              <DownloadAppButton
                copy="Download App"
                width="205px"
                height="44px"
                eventTrackId="top-download-app-button"
              />
              <MenuButton onClick={openHamburgerMenu} />
            </div>
            <HamburgerMenu
              isOpen={isOpen}
              linkSize={`${TABLET_NAVBAR_LINK_FONT_SIZE}px`}
              navItems={tabletHambergurItemGroups}
              closeMenu={closeHamburgerMenu}
            />
          </section>
        </div>
      </div>
      <div className="desktop-only">
        <div
          style={{
            width: '100%',
            height: '65px',
            padding: '15px 70px',
            backgroundColor: 'white',
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <section
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 'inherit',
            }}
          >
            <LogoButton />
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              {navItemGroups.map((navItem) => (
                <a
                  key={navItem.title}
                  style={{
                    position: 'relative',
                    height: '32px',
                    padding: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    flexShrink: 0,
                  }}
                  href={navItem.path}
                  data-event-track-id={navItem.eventTrackId}
                  target={navItem.newTab ? '_blank' : undefined}
                  onMouseEnter={handleNavItemMouseEnter(navItem.title)}
                  onMouseLeave={handleNavItemMouseLeave}
                  onClick={handleNavItemMouseClick(navItem.title)}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <Text
                      tag="span"
                      fontFamily="NewAvertaPE"
                      fontWeight="400"
                      fontSize={`${DESKTOP_NAVBAR_LINK_FONT_SIZE}px`}
                      color={hoveredNavBarItemTitle === navItem.title ? '#4FC3C4' : '#4D4D4D'}
                      lineHeight="1"
                    >
                      {navItem.title}
                    </Text>
                    {navItem.icon && (
                      <Icon
                        icon={navItem.icon}
                        color={hoveredNavBarItemTitle === navItem.title ? '#4FC3C4' : '#4D4D4D'}
                        style={{ width: '24px', height: '24px' }}
                      />
                    )}
                  </div>
                  {navItem.image && navItem.title === activeNavBarItemTitle && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '100%',
                        left: '50%',
                        transform: 'translateX(-50%) translateY(12px)',
                        visibility: hoveredNavBarItemTitle === navItem.title ? undefined : 'hidden',
                        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        padding: '10px',
                        background: 'white',
                      }}
                    >
                      <img
                        alt={navItem.image.alt}
                        src={navItem.image.src}
                        width={navItem.image.width}
                        height={navItem.image.height}
                        loading="lazy"
                      />
                    </div>
                  )}
                </a>
              ))}
              <MenuButton onClick={openHamburgerMenu} />
            </div>
            <HamburgerMenu
              isOpen={isOpen}
              linkSize={`${DESKTOP_NAVBAR_LINK_FONT_SIZE}px`}
              navItems={desktopHambergurItemGroups}
              closeMenu={closeHamburgerMenu}
            />
          </section>
        </div>
      </div>
    </section>
  );
};
