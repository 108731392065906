import * as React from 'react';
import { Text } from '../../text';
import downloadIconWebp from './images/download.webp';

type Props = {
  copy: string;
  width?: string;
  height?: string;
  eventTrackId: string;
};

export const DownloadAppButton: React.FC<Props> = ({ copy, width, height, eventTrackId }) => {
  return (
    <a
      style={{
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: width,
        height: height,
        borderRadius: '30px',
        backgroundColor: '#4FC3C4',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
        gap: '10px',
        cursor: 'pointer',
        textDecoration: 'none',
      }}
      href="https://piccollage.onelink.me/tJGq/1xs5mxhq?af_qr=true"
      target="__blank"
      data-event-track-id={eventTrackId}
    >
      <img src={downloadIconWebp} alt="cta download button icon" width="28px" height="28px" loading="lazy" />
      <Text tag="span" fontFamily="NewAvertaPE" fontWeight="bold" fontSize="16px" color="white" lineHeight="1">
        {copy}
      </Text>
    </a>
  );
};
