import * as React from 'react';
import { Text } from '../../../text';

interface Props {
  image: string;
  imageWidth: number;
  imageHeight: number;
  imageScale?: number;
  iconImage: string;
  iconSize: string;
  title: string;
  titleFontSize: string;
  titleLineHeight: string;
  titleTop: string;
  titleLeft: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  backgroundColor: string;
  borderRadius?: string;
  animationDelay: number;
}

export const KeyMatrixCard: React.FC<Props> = ({
  image,
  imageWidth,
  imageHeight,
  imageScale = 1,
  iconImage,
  iconSize,
  title,
  titleFontSize,
  titleLineHeight,
  titleTop,
  titleLeft,
  width,
  height,
  maxWidth,
  maxHeight,
  backgroundColor,
  borderRadius,
  animationDelay,
}) => {
  const topFloatingDuration = 3000;
  const rightFloatingDuration = 4000;

  const [floatedTop, setFloatedTop] = React.useState(false);
  React.useEffect(() => {
    let interval: NodeJS.Timer | null = null;
    const timeout = setTimeout(() => {
      interval = setInterval(() => {
        setFloatedTop((prev) => !prev);
      }, topFloatingDuration);
    }, animationDelay);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval ?? 0);
    };
  }, [topFloatingDuration, animationDelay]);

  const [floatedRight, setFloatedRight] = React.useState(false);
  React.useEffect(() => {
    let interval: NodeJS.Timer | null = null;
    const timeout = setTimeout(() => {
      interval = setInterval(() => {
        setFloatedRight((prev) => !prev);
      }, rightFloatingDuration);
    }, animationDelay);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval ?? 0);
    };
  }, [rightFloatingDuration]);

  return (
    <div
      style={{
        position: 'relative',
        width,
        height,
        maxWidth,
        maxHeight,
        borderRadius: borderRadius,
        overflow: 'hidden',
        background: backgroundColor,
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: '0',
          bottom: '0',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          width={imageWidth}
          height={imageHeight}
          style={{
            width: `calc((${imageWidth} / ${imageHeight}) * ${imageScale} * ${height})`,
            height: `calc(${imageScale} * ${height})`,
            objectFit: 'cover',
            position: 'relative',
            left: floatedRight ? 0 : 0,
            top: floatedTop ? 0 : 0,
            WebkitTransition: `top ${topFloatingDuration / 1000}s ease, left ${rightFloatingDuration / 1000}s ease`,
          }}
          src={image}
          alt={`key matrix of ${title}`}
          loading="lazy"
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: titleTop,
          left: titleLeft,
          objectFit: 'contain',
        }}
      >
        <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <Text
            fontSize={titleFontSize}
            fontFamily="NewAvertaPE"
            fontWeight="600"
            lineHeight={titleLineHeight}
            textAlign="center"
            tag={'h2'}
          >
            {title}
          </Text>
          {iconImage && (
            <img
              style={{ width: iconSize, height: iconSize }}
              width={iconSize}
              height={iconSize}
              src={iconImage}
              alt={`key matrix icon image of ${title}`}
              loading="lazy"
            />
          )}
        </div>
      </div>
    </div>
  );
};
