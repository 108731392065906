import * as React from 'react';
import { Text } from '../../../text';
import { AcceptCookieButton } from './accept-cookie-button';
import { CancelCookieButton } from './cancel-cookie-button';

type Props = {
  copy: JSX.Element;
  link: string;
  width: string;
  onConsent: () => void;
  onCancel: () => void;
};

export const CookiesSection: React.FC<Props> = ({ copy, link, width, onConsent, onCancel }) => {
  return (
    <div
      style={{
        width,
        display: 'flex',
        transition: 'opacity 0.5s ease',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        padding: '16px 12px',
        backgroundColor: 'white',
        borderRadius: '16px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      }}
    >
      <CancelCookieButton onClick={onCancel} />
      <div style={{ flexGrow: 1 }}>
        <Text tag="span" fontFamily="NewAvertaPE" fontSize="14px">
          {copy}{' '}
          <a href="https://cardinalblue.com/tos" target="__blank">
            <Text tag="span" fontFamily="NewAvertaPE" color="black" fontWeight="bold" underline={true}>
              {link}
            </Text>
          </a>
          .
        </Text>
      </div>
      <div style={{ flexShrink: 0 }}>
        <AcceptCookieButton copy="Accept" onClick={onConsent} />
      </div>
    </div>
  );
};
