import * as React from 'react';

import { DownloadAppButtons } from './download-app-buttons/index';
import { SocialsSection } from './socials-section';
import { PatentSection } from './patent-section';
import { LinksSection } from './links-section';
import background from './background.png';
import {
  DESKTOP_NAVBAR_LINK_FONT_SIZE,
  MOBILE_NAVBAR_LINK_FONT_SIZE,
  TABLET_NAVBAR_LINK_FONT_SIZE,
} from '../../../styles/styles';

type Props = {};

export const FooterSection: React.FC<Props> = ({}) => {
  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}>
      <div className="desktop-only">
        <div
          style={{ padding: '127px 45px 46px 50px', display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}
        >
          <div style={{ padding: '12px 0 0', display: 'flex', flexFlow: 'column', justifyContent: 'space-between' }}>
            <LinksSection
              width="360px"
              flexFlow="row"
              justifyContent="space-between"
              linkSize={`${DESKTOP_NAVBAR_LINK_FONT_SIZE}px`}
            />
            <PatentSection />
          </div>
          <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-end', gap: '84px' }}>
            <DownloadAppButtons buttonWidth="165px" buttonHeight="54px" gap="24px" />
            <SocialsSection size="50px" gap="24px" />
          </div>
        </div>
      </div>
      <div className="tablet-only">
        <div style={{ padding: '80px 20px 20px', display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
          <div style={{ padding: '11px 0 0', display: 'flex', flexFlow: 'column', justifyContent: 'space-between' }}>
            <LinksSection
              width="320px"
              flexFlow="row"
              justifyContent="space-between"
              linkSize={`${TABLET_NAVBAR_LINK_FONT_SIZE}px`}
            />
            <PatentSection />
          </div>
          <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-end', gap: '84px' }}>
            <DownloadAppButtons buttonWidth="136px" buttonHeight="45px" gap="12px" />
            <SocialsSection size="40px" gap="8px" />
          </div>
        </div>
      </div>
      <div className="mobile-only">
        <div
          style={{
            padding: '80px 40px 20px',
            display: 'flex',
            flexFlow: 'column-reverse',
            gap: '50px',
          }}
        >
          <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', gap: '50px' }}>
            <LinksSection width="100%" flexFlow="column" gap="8px" linkSize={`${MOBILE_NAVBAR_LINK_FONT_SIZE}px`} />
            <PatentSection />
          </div>
          <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', gap: '50px' }}>
            <DownloadAppButtons buttonWidth="165px" buttonHeight="54px" gap="12px" />
            <SocialsSection size="40px" gap="8px" />
          </div>
        </div>
      </div>
    </div>
  );
};
