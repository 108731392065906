export const SECTION_GAP = 120;
export const DESCRIPTION_COLOR = '#666666';

export const DESKTOP_TITLE_FONT_SIZE = 28;
export const DESKTOP_TITLE_EMPHASIZE_FONT_SIZE = 40;
export const DESKTOP_TITLE_LINE_HEIGHT = 48;
export const DESKTOP_SUBTITLE_FONT_SIZE = 28;
export const DESKTOP_SUBTITLE_LINE_HEIGHT = 35;
export const DESKTOP_DESCRIPTION_FONT_SIZE = 20;
export const DESKTOP_NAVBAR_LINK_FONT_SIZE = 16;
export const DESKTOP_SIDE_PADDING = 72;
export const DESKTOP_MAX_WIDTH = 1280;

export const TABLET_TITLE_FONT_SIZE = 28;
export const TABLET_TITLE_EMPHASIZE_FONT_SIZE = 40;
export const TABLET_TITLE_LINE_HEIGHT = 48;
export const TABLET_SUBTITLE_FONT_SIZE = 28;
export const TABLET_SUBTITLE_LINE_HEIGHT = 35;
export const TABLET_DESCRIPTION_FONT_SIZE = 20;
export const TABLET_NAVBAR_LINK_FONT_SIZE = 16;
export const TABLET_SIDE_PADDING = 32;

export const MOBILE_TITLE_FONT_SIZE = 20;
export const MOBILE_TITLE_EMPHASIZE_FONT_SIZE = 28;
export const MOBILE_TITLE_LINE_HEIGHT = 35;
export const MOBILE_SUBTITLE_FONT_SIZE = 20;
export const MOBILE_SUBTITLE_LINE_HEIGHT = 25;
export const MOBILE_DESCRIPTION_FONT_SIZE = 20;
export const MOBILE_NAVBAR_LINK_FONT_SIZE = 16;
export const MOBILE_SIDE_PADDING = 16;
