import oneLinkQrCode from './images/one-link-qr-code.webp';

export interface NavItem {
  title: string;
  path?: string;
  eventTrackId?: string;
  newTab: boolean;
  icon?: string;
  image?: {
    src: string;
    alt: string;
    width: number;
    height: number;
  };
}

export const navItemGroups: NavItem[] = [
  { title: 'Support & FAQ', path: 'https://piccollage.zendesk.com/hc/en-us', newTab: true },
  { title: 'Try Web Editor', path: 'https://www.photocards.ai', eventTrackId: 'web-editor-link', newTab: true },
  {
    title: 'Download The App',
    newTab: true,
    icon: 'prime:mobile',
    eventTrackId: 'top-download-app-button',
    image: { src: oneLinkQrCode, alt: 'piccollage download link qr code', width: 140, height: 140 },
  },
];

export const desktopHambergurItemGroups: NavItem[] = [
  { title: 'Login', path: '/login_page', newTab: false },
  { title: 'Company', path: 'https://picc.co/', newTab: true },
  { title: 'Blog', path: 'https://blog.piccollage.com/', newTab: true },
];

export const tabletHambergurItemGroups: NavItem[] = [
  { title: 'Support & FAQ', path: 'https://piccollage.zendesk.com/hc/en-us', newTab: true },
  { title: 'Login', path: '/login_page', newTab: false },
  { title: 'Company', path: 'https://picc.co/', newTab: true },
  { title: 'Blog', path: 'https://blog.piccollage.com/', newTab: true },
];

export const mobileHambergurItemGroups: NavItem[] = [
  { title: 'Support & FAQ', path: 'https://piccollage.zendesk.com/hc/en-us', newTab: true },
  { title: 'Login', path: '/login_page', newTab: false },
  { title: 'Company', path: 'https://picc.co/', newTab: true },
  { title: 'Blog', path: 'https://blog.piccollage.com/', newTab: true },
];
