import * as React from 'react';
import { TitleSection } from './title-section';
import { ScreenSizeEnum } from '../../../models/screen-size-enum';
import { cards } from './cards';
import { KeyMatrixCard } from './key-matrix-card';
import KeyMatricesCarousell from './key-matrices-carousell';
import {
  DESKTOP_SUBTITLE_FONT_SIZE,
  DESKTOP_SUBTITLE_LINE_HEIGHT,
  DESKTOP_TITLE_EMPHASIZE_FONT_SIZE,
  DESKTOP_TITLE_FONT_SIZE,
  DESKTOP_TITLE_LINE_HEIGHT,
  MOBILE_SIDE_PADDING,
  MOBILE_SUBTITLE_FONT_SIZE,
  MOBILE_SUBTITLE_LINE_HEIGHT,
  MOBILE_TITLE_EMPHASIZE_FONT_SIZE,
  MOBILE_TITLE_FONT_SIZE,
  MOBILE_TITLE_LINE_HEIGHT,
  SECTION_GAP,
  TABLET_SIDE_PADDING,
  TABLET_TITLE_EMPHASIZE_FONT_SIZE,
  TABLET_TITLE_FONT_SIZE,
  TABLET_TITLE_LINE_HEIGHT,
} from '../../../styles/styles';
import { useIntersectionObserver } from '../../../hooks/use-intersection-observer';

type Props = {
  screenSize: ScreenSizeEnum;
};

export const KeyCreativeToolsSection: React.FC<Props> = ({ screenSize }) => {
  /**
   * We only want the animation to run when this seciton is visible
   */
  const { ref: wrapperRef, isIntersecting: isWrapperVisible } = useIntersectionObserver({ threshold: 0 });

  return (
    <section ref={wrapperRef}>
      <div className="mobile-only">
        <section style={{ padding: `${SECTION_GAP / 2}px ${MOBILE_SIDE_PADDING}px ${SECTION_GAP / 2}px` }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0px' }}>
            <TitleSection
              fontSize={`${MOBILE_TITLE_FONT_SIZE}px`}
              lineHeight={`${MOBILE_TITLE_LINE_HEIGHT}px`}
              emphasisFontSize={`${MOBILE_TITLE_EMPHASIZE_FONT_SIZE}px`}
              emphasisLineHeight={`${MOBILE_TITLE_LINE_HEIGHT}px`}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '42px 0 0' }}>
            <div style={{ display: 'flex', flexFlow: 'column', gap: '16px' }}>
              {cards.map(({ image, imageSize, imageScaleOnMobile, iconImage, title, color, animationDelay }) => (
                <div
                  key={title}
                  style={{
                    scrollSnapAlign: 'center',
                    flexShrink: 0,
                  }}
                >
                  <KeyMatrixCard
                    image={image}
                    imageWidth={imageSize.width}
                    imageHeight={imageSize.height}
                    imageScale={imageScaleOnMobile}
                    iconImage={iconImage}
                    iconSize="24px"
                    title={title}
                    titleFontSize={`${MOBILE_SUBTITLE_FONT_SIZE}px`}
                    titleLineHeight={`${MOBILE_SUBTITLE_LINE_HEIGHT}px`}
                    titleTop="32px"
                    titleLeft="32px"
                    width={`calc(100vw - ${MOBILE_SIDE_PADDING * 2}px)`}
                    height={`calc((100vw - ${MOBILE_SIDE_PADDING * 2}px) * 260 / 325)`}
                    borderRadius="18px"
                    backgroundColor={color}
                    animationDelay={animationDelay}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <div className="tablet-only">
        <section
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            padding: `${SECTION_GAP / 2}px ${TABLET_SIDE_PADDING}px ${SECTION_GAP / 2}px`,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TitleSection
              fontSize={`${TABLET_TITLE_FONT_SIZE}px`}
              lineHeight={`${TABLET_TITLE_LINE_HEIGHT}px`}
              emphasisFontSize={`${TABLET_TITLE_EMPHASIZE_FONT_SIZE}px`}
              emphasisLineHeight={`${TABLET_TITLE_LINE_HEIGHT}px`}
            />
          </div>
          <div
            style={{
              marginTop: '54px',
              width: '100vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <KeyMatricesCarousell
              titleFontSize={`${TABLET_TITLE_FONT_SIZE}px`}
              titleLineHeight={`${TABLET_TITLE_LINE_HEIGHT}px`}
              visible={isWrapperVisible && screenSize === ScreenSizeEnum.Tablet}
            />
          </div>
        </section>
      </div>
      <div className="desktop-only">
        <section style={{ padding: `${SECTION_GAP / 2}px 0 ${SECTION_GAP / 2}px` }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TitleSection
              fontSize={`${DESKTOP_TITLE_FONT_SIZE}px`}
              lineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
              emphasisFontSize={`${DESKTOP_TITLE_EMPHASIZE_FONT_SIZE}px`}
              emphasisLineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
            />
          </div>
          <div style={{ marginTop: '46px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
              style={{
                width: '1240px',
                display: 'flex',
                flexFlow: 'column',
                gap: '32px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '32px',
                }}
              >
                <KeyMatrixCard
                  image={cards[0].image}
                  iconImage={cards[0].iconImage}
                  imageWidth={cards[0].imageSize.width}
                  imageHeight={cards[0].imageSize.height}
                  iconSize="40px"
                  title={cards[0].title}
                  titleFontSize={`${DESKTOP_SUBTITLE_FONT_SIZE}px`}
                  titleLineHeight={`${DESKTOP_SUBTITLE_LINE_HEIGHT}px`}
                  titleTop="52px"
                  titleLeft="57px"
                  width="768px"
                  height="324px"
                  borderRadius="50px"
                  backgroundColor={cards[0].color}
                  animationDelay={cards[0].animationDelay}
                />
                <KeyMatrixCard
                  image={cards[1].image}
                  iconImage={cards[1].iconImage}
                  imageWidth={cards[1].imageSize.width}
                  imageHeight={cards[1].imageSize.height}
                  iconSize="40px"
                  title={cards[1].title}
                  titleFontSize={`${DESKTOP_SUBTITLE_FONT_SIZE}px`}
                  titleLineHeight={`${DESKTOP_SUBTITLE_LINE_HEIGHT}px`}
                  titleTop="52px"
                  titleLeft="57px"
                  width="496px"
                  height="324px"
                  borderRadius="50px"
                  backgroundColor={cards[1].color}
                  animationDelay={cards[1].animationDelay}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '32px',
                }}
              >
                <KeyMatrixCard
                  image={cards[2].image}
                  iconImage={cards[2].iconImage}
                  imageWidth={cards[2].imageSize.width}
                  imageHeight={cards[2].imageSize.height}
                  iconSize="40px"
                  title={cards[2].title}
                  titleFontSize={`${DESKTOP_SUBTITLE_FONT_SIZE}px`}
                  titleLineHeight={`${DESKTOP_SUBTITLE_LINE_HEIGHT}px`}
                  titleTop="52px"
                  titleLeft="57px"
                  width="496px"
                  height="324px"
                  borderRadius="50px"
                  backgroundColor={cards[2].color}
                  animationDelay={cards[2].animationDelay}
                />
                <KeyMatrixCard
                  image={cards[3].image}
                  iconImage={cards[3].iconImage}
                  imageWidth={cards[3].imageSize.width}
                  imageHeight={cards[3].imageSize.height}
                  iconSize="40px"
                  title={cards[3].title}
                  titleFontSize={`${DESKTOP_SUBTITLE_FONT_SIZE}px`}
                  titleLineHeight={`${DESKTOP_SUBTITLE_LINE_HEIGHT}px`}
                  titleTop="52px"
                  titleLeft="57px"
                  width="768px"
                  height="324px"
                  borderRadius="50px"
                  backgroundColor={cards[3].color}
                  animationDelay={cards[3].animationDelay}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};
