import * as React from 'react';
import { Text } from '../../../text';
import titleBackgroundUrl from './title-background.webp';

type Props = {
  width: string;
  height: string;
  titleFontSize: string;
  titleLineHeight: string;
};

export function Header({ width, height, titleFontSize, titleLineHeight }: Props) {
  return (
    <section style={{ display: 'inline-block', position: 'relative', overflow: 'hidden', width, height }}>
      <img alt="hero section title background" src={titleBackgroundUrl} width="100%" height="100%" />
      <section
        style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-50%)' }}
      >
        <Text
          tag="h1"
          fontFamily="Weekly"
          fontSize={titleFontSize}
          fontWeight="bold"
          color="white"
          lineHeight={titleLineHeight}
          whiteSpace="nowrap"
          textShadow="0px 0px 10px #AB7624"
        >
          Create & Celebrate
        </Text>
      </section>
    </section>
  );
}
